.site {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.site-content {
  flex: 1;
  min-height: 97vh;
}

.errorPop {
  background-color: red !important;
  color: white !important;
}
.ui.top.popup.errorPop:before {
  background: red;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.center-content {
  display: inline-block;
  position:  fixed !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 800px;
  height: 100px;
  margin: auto !important;
}

.text-center {
  text-align: center !important;
}

@media screen and (max-width: 767px) {
  .additionalContent {
      width: 50% !important;
      float: left;
  }
}

/* This is to override the sizing of the globalSearch in the SideBar */
.ui.icon.input>input {
  width: 100%
}